import React, { useState, useCallback } from 'react';
import { withNamedStores } from '../../store/state';
import { useHistory } from "react-router-dom";
import { deleteProject } from '../requests'
import ConfirmDelete from './ConfirmDelete';
import { withDjangoBuilderNamedStores } from '../store/state'
import Editable from '../Editable';
import { Card } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import actions from '../store/actions';
import { updateProject } from '../requests'

const ProjectCard = (props) => {

  const { dispatch, jwt, project } = props;
  
  const history = useHistory();
  const [deleteVisibility, setDeleteVisibility] = useState("hidden");
  const [editableProjectName, setEditableProjectName] = useState(project.name);
  const [editableProjectDescription, setEditableProjectDescription] = useState(project.description);
  const [deleteHover, setDeleteHover ] = useState(false);
  const [projectHover, setProjectHover ] = useState(false);

  const icon_height = 20;
  const icon_color = '#000';
  const deleteOpacity = deleteHover ? "100%" : "20%";
  const projectOpacity = projectHover ? "100%" : "50%";
  
  const handleProjectClick = useCallback(
    (project) => history.push(`/project/${project.uuid}`), [history]
  );

  const handleDeleteProject = async (project) => {
    await deleteProject(jwt, dispatch, project.uuid);
  }
  
  const updateProjectValues = (values) => {
    updateProject(jwt, project.uuid, values).then((project) => {
      dispatch({type: actions.SET_PROJECT, project});
    })
  }

  const editableProject = <Editable
    value={editableProjectName}
    inputStyle={{width: "100%"}}
    onInput={(value) => setEditableProjectName(value)}
    finishEdit={() => updateProjectValues({name: editableProjectName})}
  />
  
  const editableDescription = <Editable
    type="textarea"
    value={editableProjectDescription}
    onInput={(value) => setEditableProjectDescription(value)}
    finishEdit={() => updateProjectValues({description: editableProjectDescription})}
    suggestions={[`The ${project.name} Project`, "Just another Project"]}
  />

  return <Card
    className="text-center my-2 h-100"
    onMouseOver={() => setProjectHover(true) }
    onMouseOut={() => setProjectHover(false) }
  >
    <Card.Body role="button" className="p-0 pt-2">
      <Card.Title
        role="button"
        className="display-5"
        onClick={() => handleProjectClick(project)}
      >
        <span className="d-none">{editableProject}</span>
        {project.name}
      </Card.Title>
      <Card.Text>
        {editableDescription}
      </Card.Text>
    </Card.Body>
    <Card.Img
      variant="top"
      src="static/img/preview.png"
      className="w-75 mx-auto"
      role="button"
      style={{opacity: projectOpacity}}
      onClick={() => handleProjectClick(project)}
    />
    <Card.Footer>
      <small className="text-muted">
        {project.apps.length} app
        {project.apps.length === 1 ? "" : "s"}
      </small>
        <ConfirmDelete
          title={`Delete '${project.name}'?`}
          style={{visibility: deleteVisibility, zIndex: 9999, position: "absolute"}}
          onConfirm={() => handleDeleteProject(project)}
          onCancel={() => setDeleteVisibility("hidden")}
        />
        <span role="button">
          <Trash
            onMouseOver={() => setDeleteHover(true) }
            onMouseOut={() => setDeleteHover(false) }
            role="button"
            onClick={() => setDeleteVisibility("visible")}
            color={icon_color}
            size={icon_height}
            className="float-right"
            style={{
              opacity: deleteOpacity,
            }}
          />
        </span>
    </Card.Footer>
  </Card>
};

export default withNamedStores(
  withDjangoBuilderNamedStores(ProjectCard, ['dispatch']), ['jwt']
);