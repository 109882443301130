import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import { initialState, Reducer } from './reducer';

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

const Context = createContext();

function useDispatchStore() {
  const dispatch = useContext(Context)[1];
  return { dispatch };
}

function useKeyfromStore(key) {
  const context = useContext(Context)[0]
  const { [key]: val } = context;
  return { [key]: val };
}

function asComponent(Component, store, props) {
  return <Component {...store} {...props} />
}

const storeNames = {
  projects: (Component) => (props) => asComponent(Component, useKeyfromStore('projects'), props),
  apps: (Component) => (props) => asComponent(Component, useKeyfromStore('apps'), props),
  models: (Component) => (props) => asComponent(Component, useKeyfromStore('models'), props),
  fields: (Component) => (props) => asComponent(Component, useKeyfromStore('fields'), props),
  relationships: (Component) => (props) => asComponent(Component, useKeyfromStore('relationships'), props),
  dispatch: (Component) => (props) => asComponent(Component, useDispatchStore(), props),
};

// Applies multiple stores to a Component
const withDjangoBuilderNamedStores = (Component, stores) => {
  let storeComponent = Component;
  stores.forEach((storeName) => {
    if (storeNames[storeName] !== undefined) {
      storeComponent = storeNames[storeName](storeComponent);
    } else {
      console.error(`Unknown Store ${storeName}`);
    }
  });
  return storeComponent;
};

export { Store, withDjangoBuilderNamedStores };