import { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import actions from '../../store/actions';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Relationship from './Relationship.js';
import { updateRelationship } from '../../requests'

function Relationships(props) {

  console.debug('Relationships', props);
  
  const { model, relationships, dispatch, jwt } = props;

  const relationships_length = Object.keys(relationships).length;

  const modelRelationships = model.relationships.map(r => relationships[r.uuid]).sort(
    (relationship1, relationship2) => relationship1.order - relationship2.order
  );
  const [moveableRelationships, setMoveableRelationships] = useState(modelRelationships);

  useEffect(() => {
    if (model.relationships.length !== moveableRelationships.length){
      const newModels = model.relationships.map(r => relationships[r.uuid]).sort(
        (relationship1, relationship2) => relationship1.order - relationship2.order
      );
      setMoveableRelationships(newModels);
    }
  }, [relationships_length, relationships, model.relationships, moveableRelationships]);

  const updateRelationshipValues = useCallback((relationship, values) => {
    return updateRelationship(jwt, relationship.uuid, values).then((relationship) => {
      dispatch({type: actions.SET_RELATIONSHIP, relationship})
    })
  }, [dispatch, jwt]);

  const dropComplete = useCallback(() => {
    moveableRelationships.forEach((relationship, i) => {
      updateRelationshipValues(relationship, {order: i});
    })
  }, [moveableRelationships, updateRelationshipValues]);
  
  const dragField = useCallback((dragIndex, hoverIndex) => {
    const draggedField = moveableRelationships[dragIndex];
    setMoveableRelationships(
      update(moveableRelationships, {
        $splice: [ [dragIndex, 1], [hoverIndex, 0, draggedField],],
      })
    );
    
  }, [moveableRelationships, setMoveableRelationships]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        {moveableRelationships.map((relationship, i) => 
          <Relationship
            model={model.uuid}
            key={relationship.uuid}
            index={i}
            relationship={relationship}
            dragField={dragField}
            dropComplete={dropComplete}
          />
        )}
      </DndProvider>
    </div>
  )
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      Relationships, ['dispatch', 'relationships']
  ),['jwt']
);