import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Activate from '../pages/Activate';
import NotFound from '../pages/NotFound';
import Settings from '../pages/Settings';
import TopNav from '../pages/TopNav';
import Example from '../pages/Example';

import Container from 'react-bootstrap/Container';
import DjangoBuilderDashboard from './DjangoBuilderDashboard';
import LoadingWrapper from './LoadingWrapper';
import Project from './pages/Project';
import { Store } from './store/state';

import './DjangoBuilder.css'

function DjangoBuilder() {

  return (
    <React.StrictMode>
      <Store>
        <Router>
          <TopNav />
          <Container fluid className="p-0 mt-5 django_builder">
            <LoadingWrapper>
              <Switch>
                <Route path="/activate/:activation_key"><Activate /></Route>
                <Route path="/login"><Redirect push to="/" /></Route>
                <Route exact path="/"><DjangoBuilderDashboard /></Route>
                <Route exact path="/project/:projectid/"><Project /></Route>
                <Route path="/project/:projectid/:page+"><Project /></Route>
                <Route path="/example"><Example /></Route>
                <Route exact path="/settings"><Settings /></Route>
                <Route path="*"><NotFound /></Route>
              </Switch>
            </LoadingWrapper>
          </Container>
        </Router>
      </Store>
    </React.StrictMode>
  );
}

export default DjangoBuilder;