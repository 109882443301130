import React, {useState, useEffect } from 'react';
import { withNamedStores } from '../../store/state';
import { withDjangoBuilderNamedStores } from '../store/state'
import Highlight from 'react-highlight'
import ModelCode from "../rendering/ModelCode";
import AppCode from "../rendering/AppCode";
import NewModel from '../components/Model/NewModel';
import Models from '../components/Model/Models';
import { updateProject } from '../requests'
import actions from '../store/actions';
import './Project.css';
import { Button, ButtonGroup } from 'react-bootstrap';

const VIEW_CLASS_BASED = "class"
const VIEW_FUNCTION_BASED = "function"


function AppPage(props) {

  console.debug("AppPage:", props)

  const { projectData, app, model, page, models, fields, relationships, jwt, dispatch } = props;

  const { view_type } = projectData;

  const [modelData, setModelData] = useState("");
  
  useEffect(() => {
    async function fetchData() {
      const modelFields = Object.values(fields).filter(field => field.model === model.uuid);
      const model_code = new ModelCode(projectData, app, model, modelFields)
      const data = await model_code.page(page)
      setModelData(data)
    }
    if (model) {
      fetchData();
    }
  }, [page, projectData, app, model, fields]);

  const setViewRendering = (new_view_type) => {
    updateProject(jwt, projectData.uuid, {view_type: new_view_type}).then((project) => {
      dispatch({type: actions.SET_PROJECT, project});
    })
  }

  if (!app) {
    return <div>App not found</div>;
  }

  const appModels = Object.values(app.models).map(m => models[m.uuid])
  const modelFields = []
  appModels.forEach(model => {
    modelFields.push(...Object.values(model.fields).map(f => fields[f.uuid]))
  })

  const relationshipFields = []
  appModels.forEach(model => {
    relationshipFields.push(...Object.values(model.relationships).map(r => relationships[r.uuid]))
  })

  const app_code = new AppCode(projectData, app, appModels, modelFields, relationshipFields)

  if (model) {
    return <div>
      {
        <>
          <Highlight className='django'>
            {modelData}
          </Highlight>
        </>
      }
    </div>
  }
  
  if (page === "models.py") {
    return <>
      <Highlight className='python'>
        {app_code.model_imports()}
      </Highlight>
      <pre style={{overflow: "visible"}}>
      {"\n"}
        <Models appid={app.uuid} models={appModels} />
        <NewModel appid={app.uuid}  model_count={appModels.length} />
      </pre>
    </>
  }

  if (page === "views.py") {
    return <>
      <ButtonGroup size="sm" className="mb-3" aria-label="Basic example">
        <Button as={"label"} variant="link"> View rendering:</Button>
        <Button as={"label"} onClick={() => setViewRendering(VIEW_FUNCTION_BASED)} variant={view_type === VIEW_FUNCTION_BASED ? "light": "link"}>Function Based</Button>
        <Button as={"label"} onClick={() => setViewRendering(VIEW_CLASS_BASED)} variant={view_type === VIEW_CLASS_BASED ? "light": "link"}>Class Based</Button>
      </ButtonGroup>
      <Highlight className='python'>{app_code.views_code()}</Highlight>
    </>
  }

  return <div>
    {
      page === ""
      ? <div>
        What to show for an APP?
      </div>
      : <>
        <Highlight className='python'>
          {app_code.page(page)}
        </Highlight>
      </>
    }
  </div>

}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      AppPage, ['projects', 'apps', 'models', 'fields', 'relationships', 'dispatch']
  ),['user', 'jwt']
);