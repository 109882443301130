export function next_prefixed_name(prefix, existing){
  if (existing.length === 0) {
    return prefix;
  }
  const numbers = [...Array(100).keys()]
  numbers.shift()  // exclude 0
  const new_field_index = numbers.find((i) => {
    return !existing.includes(`${prefix}${i}`)
  });
  if (new_field_index === undefined) {
    return `${prefix}new`
  }
  return `${prefix}${new_field_index}`;
}