import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { withNamedStores } from '../store/state';
import PropTypes from 'prop-types';
import { createProject } from './requests'
import { withDjangoBuilderNamedStores } from './store/state'
import ProjectCard from './pages/ProjectCard';
import actions from './store/actions';

import { Alert, Container, Row, Col, Button, Form, Card } from 'react-bootstrap';

function DjangoBuilderDashboard(props) {

  console.debug("DjangoBuilderDashboard:", props)

  const { dispatch, projects, user } = props;

  const history = useHistory();

  const userDisplay = user.display_name ? "'"+ user.display_name + "'" : "";
  const baseWelcome = ">>> Welcome to Django Builder" + userDisplay;
  const [seconds, setSeconds] = useState(0);
  const welcomeText = baseWelcome.substring(0, seconds + 1);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => {
        return seconds + 1
      });
    }, 100);
    return function cleanup() {
      clearInterval(interval);
    };
  }, []);  

  const [name, setName] = useState(null);
  const [module, setModule] = useState(null);
  const [description, setDescription] = useState(null);
  const [projectError, setProjectError] = useState(null);
  const [validated, setValidated] = useState(false);
  const [autocompleteOn, setAutocomplete] = useState(true);

  const resetProjectsForm = (form) => {
    setValidated(false);
    setName("")
    setModule("")
    setDescription("")
    form.reset()
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.debug('New Project', name, description)
    event.preventDefault();
    setProjectError(null);
    setValidated(true);
    if (form.checkValidity()) {
      handleCreate(form).then((project) => {
        resetProjectsForm(form)
        const { uuid } = project;
        history.push("/project/" + uuid);
      }).catch((e) => {
        const eMessage = "Sorry there was an error creating that project"
        setProjectError(eMessage)
        console.error(eMessage)
      });
    } else {
      event.stopPropagation();
    }
  };

  const handleCreate = async () => {
    const project = await createProject(props.jwt, name, module, description);
    console.debug("New Project", project)
    await dispatch({type: actions.ADD_PROJECT, project})
    return project
  }

  const userSetModule = (module) => {
    setAutocomplete(false)
    setModule(module)
  }

  const userSetDescription = (description) => {
    setAutocomplete(false)
    setDescription(description)
  }

  const setNameAndModule = (name) => {
    setName(name)
    if (autocompleteOn) {
      setModule(name.toLowerCase())
      setDescription(`The ${name} project`)
    }
  }

  const welcome = <div>
    <div className="display-6 text-center my-4">
      <code style={{wordBreak: "all"}}>{welcomeText}</code>
    </div>
  </div>

  const newProject = <Card className="text-center my-2 h-100">
    <Form className="w-100 m-0" validated={validated} onSubmit={handleSubmit} noValidate >
      <Card.Body role="button">
        <Card.Title className="text-center display-5">
          Create New Project
        </Card.Title>
        { projectError ?
          <Alert variant="danger" show={projectError}>
            {projectError}
          </Alert> : undefined 
        }
        <Form.Group controlId="formAddProjectName">
          <Form.Control required type="text" placeholder="Enter project name" value={name || ""} onChange={(e) => setNameAndModule(e.target.value)} />
          <Form.Control.Feedback type="invalid">Please enter a project name.</Form.Control.Feedback>
        </Form.Group>
        
        <Form.Group controlId="formAddProjectModule">
          <Form.Control required type="text" placeholder="Enter project module" value={module || ""}
            onChange={(e) => userSetModule(e.target.value.toLowerCase())}
            onInput={(e) => e.target.value = ("" + e.target.value).toLowerCase()}
          />
          <Form.Control.Feedback type="invalid">Enter module name.</Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formAddProjectDescription">
          <Form.Control required type="text" placeholder="Enter project description"  value={description || ""} onChange={(e) => userSetDescription(e.target.value)} />
          <Form.Control.Feedback type="invalid">Please enter a project description.</Form.Control.Feedback>
        </Form.Group>

        <Button id="add_project" variant="secondary" type="submit" className="w-50">
          Add Project
        </Button>
      </Card.Body>
    </Form>
  </Card>

  const projectCardDeck = <Container fluid className="p-0">
  <Row className="justify-content-center">
    {
      Object.values(projects).map((project) => {
        return <Col xs={10} sm={6} md={6} lg={4} key={project.uuid} className="mb-2">
          <ProjectCard project={project} />
        </Col>
      })
    }
    <Col xs={10} sm={6} md={6} lg={4} className="mb-2">
      {newProject}
    </Col>
  </Row>
  </Container>

  return <Container className="mt-4 p-0 django_builder_dashboard">
    <Row>
      <Col lg={{span:12}} md={12}>
        {welcome}
      </Col>
      <Col lg={{span:12}} md={12}>
        {projectCardDeck}
      </Col>
    </Row>
  </Container>
}

DjangoBuilderDashboard.propTypes = {
  user: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
  })
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      DjangoBuilderDashboard, ['projects', 'dispatch']
  ), ['user', 'jwt']
);