import React, { useState, useEffect } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

const Editable = ({
    value,
    finishEdit,
    onInput,
    inputStyle={},
    type,
    ...props
  }) => {

    const [editting, setEditing] = useState(false);
    const [inputSize, setInputSize] = useState(value.length);
    const inputRef = React.createRef();

    let { suggestions } = props;

    suggestions = suggestions || [];
    
    useEffect(() => {
      if (inputRef && inputRef.current && editting === true) {
        inputRef.current.focus();
      }
    }, [editting, inputRef]);

    const onChange = (e) => {
      setInputSize(e.target.value.length)
    }

    const finish = () => {
      setEditing(false);
      finishEdit()
    }

    const suggestionClicked = (e, suggestion) => {
      e.preventDefault();
      e.stopPropagation();
      setInputSize(suggestion.length)
      onInput(suggestion)
    }

    const suggestionComponents = suggestions.length > 0 ? <div className="p-1">
      <div className="text-muted">Quick:</div>
        {
          suggestions.map((suggestion, i) =>
            <div key={i} role="button" className="m-1" onClick={(e) => {suggestionClicked(e, suggestion)}} onBlur={() => {}}>
              {suggestion}
            </div>
          )
        }
    </div>
    : null;

    const baseControlStyle = type === "textarea" ? {
      top: "100%",
      left: "0",
    }: {
      left: "0",
    }

    const saveClass = suggestions.length > 0 ? "border rounded m-1": "";

    const controlComponent = <>
      <div
        className="border rounded px-1"
        style={{
          ...baseControlStyle,
          fontWeight: "normal",
          fontFamily: "monospace",
          fontSize: "14px",
          zIndex: 999999999,
          position: "absolute",
          whiteSpace: "nowrap",
          backgroundColor: "white",
          color: "black",
          minHeight: "100%",
          justifyContent: "center",
          display:"flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div onClick={finish} className={saveClass}>
            Save <CheckCircleFill color="green" size={14} />
          </div>
          {suggestionComponents}
        </div>
      </div>
    </>

    const baseInputStyle = type === "textarea" ? {
      width: "100%"
    }: {
    }

    let allInputStyle = {
      ...baseInputStyle,
      ...inputStyle,
    }

    const inputComponent = type === "textarea" ? <textarea
      ref={inputRef}
      type="textarea"
      size={inputSize + 1}
      value={value}
      onChange={onChange}
      style={allInputStyle}
    /> : <input
      ref={inputRef}
      type="textarea"
      size={inputSize + 1}
      value={value}
      onChange={onChange}
      style={allInputStyle}
    />

    const editComponent = <span className="position-relative">
      {inputComponent}
      {controlComponent}
    </span>

    const handleKeyDown = (event) => {
      const { key } = event;
      const keys = ["Escape", "Tab"];
      const enterKey = "Enter";
      const allKeys = [...keys, enterKey];

      if (allKeys.indexOf(key) > -1) {
        finish();
      }
    }
  
    return (
      <>
        <span {...props} role="button">
          {editting ? (
            <span
              onInput={(e) => onInput(e.target.value)}
              onKeyDown={e => handleKeyDown(e)}
              // TDOD - Fix blue with suggestions
              //onBlur={finish}
            >
              {editComponent}
            </span>
          ) : (
            <span onClick={() => setEditing(true)}>
              {value || <span style={{opacity: "40%"}} className="text-muted">...</span>}
            </span>
          )}
        </span>
      </>
    );
  };

  export default Editable;