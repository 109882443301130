import React, { useState, useEffect } from 'react';
import { withNamedStores } from '../../store/state';
import Highlight from 'react-highlight'
import ProjectCode from "../rendering/ProjectCode";
import { withDjangoBuilderNamedStores } from '../store/state'

import './Project.css';


function ProjectPage(props) {

  console.debug("ProjectPage:", props)

  const { projectData, apps, models, page,} = props;
  
  const [pageData, setPageData] = useState("");
  
  useEffect(() => {
    const project_code = new ProjectCode(projectData, apps, models)
    async function fetchData() {
      const data = await project_code.page(page)
      setPageData(data)
    }
    if (page !== "") {
      fetchData();
    }
  }, [page, apps, models, projectData]);

  const highlight_type = page.endsWith(".py") ? "python" : "html"

  return <Highlight className={highlight_type}>
    {pageData}
  </Highlight>
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      ProjectPage, ['projects', 'apps', 'models']
  ),['user']
);

