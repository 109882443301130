import React, { useState } from 'react';
import { model_widget } from '../../rendering/widgets'
import { PlusCircleFill } from 'react-bootstrap-icons';
import { addModel } from '../../requests';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import { next_prefixed_name } from '../../utils.js';

const COLOR_ON = "gray";
const COLOR_OFF = "gray";


function NewModel(props) {
  
  console.debug("NewModel:", props)

  const { jwt, dispatch, appid, models } = props;

  const [hover, setHover ] = useState(false);
  
  const existingModelNames = Object.values(models).filter(
    model => model.app === appid
  ).reduce((acc, model) => [...acc, model.name], []);

  const color = hover ? COLOR_ON : COLOR_OFF;
  const opacity = hover ? "100%" : "70%";
  const icon_color = hover ? "teal" : "lightgray";
  const icon_height = 24;

  const order = props.model_count;

  const new_name = next_prefixed_name("Model", existingModelNames)

  const handleAddModel = () => {
    addModel(jwt, dispatch, { app: appid, name: new_name, order});
  }

  return (
    <div>
      <div
        className="d-inline-block position-relative"
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
        onClick={() => handleAddModel() }
        style={{opacity: opacity}}
        role="button"
      >
        <PlusCircleFill
          color={icon_color}
          size={icon_height}
          className="position-absolute"
          style={{top: "70%", left: "50%", marginTop: (-icon_height/2) + "px", marginLeft: (-icon_height/2) + "px"}}
        />
        <span style={{color: color}}>
        {
          model_widget(
            new_name,
            [{uuid: "1", name: "name", type: "CharField", args: "max_length=255"}],
            [{uuid: "1", name: "owner", type: "ForeignKey", to: "auth.User", on_delete: "CASCADE"}],
          )
        }
        </span>
      </div>
    </div>
  )
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      NewModel, ['dispatch', 'models']
  ),['jwt']
);