import React, { useState, useEffect } from 'react';

const EditableChoice = ({
    value,
    choices,
    finishEdit,
    onChange,
    ...props
  }) => {
    const [editting, setEditing] = useState(false);
    const inputRef = React.createRef();
    
    useEffect(() => {
      if (inputRef && inputRef.current && editting === true) {
        inputRef.current.focus();
      }
    }, [editting, inputRef]);

    const finish = () => {
      setEditing(false);
      finishEdit()
    }

    const editComponent = (
      <select aria-label="Default select example" ref={inputRef} defaultValue={value}
        onChange={() => onChange(inputRef.current.value)}
      >
        {
          choices.map((choice, index) => {
            return <option key={index} value={choice}>{choice}</option>
          })
        }
      </select>
    )
  
    return (
      <span {...props} role="button">
        {editting ? (
          <span onBlur={finish}>
            {editComponent}
          </span>
        ) : (
          <span onClick={() => setEditing(true)}>
            {value}
          </span>
        )}
      </span>
    );
  };

  export default EditableChoice;