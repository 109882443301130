import React, { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trash } from 'react-bootstrap-icons';

import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import { field_widget } from '../../rendering/widgets'
import { deleteField, updateField } from '../../requests';
import Editable from '../../Editable';
import EditableChoice from '../../EditableChoice';
import actions from '../../store/actions';
import ConfirmDelete from '../../pages/ConfirmDelete';

const styleDnD = {
  border: '1px dashed gray',
  backgroundColor: '#EEEEEE',
  cursor: 'move',
};

const styleNormal = {
  border: '1px solid white',
};

function Field(props) {

  console.debug('Field', props);

  const { model, open_api, jwt, dispatch, field, dragField, dropComplete, index } = props;
  const { uuid } = field;
  const choices = open_api.components.schemas.Field_type_Enum.enum;
  
  const [editableFieldName, setEditableFieldName] = useState(field.name);
  const [editableFieldType, setEditableFieldType] = useState(field.type);
  const [editableFieldArgs, setEditableFieldArgs] = useState(field.args);

  const [hover, setHover ] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState("hidden");
  
  const icon_height = 14;
  const icon_color = '#000';
  const opacity = hover ? "100%" : "20%";

  const updateFieldValues = (values) => {
    updateField(jwt, field.uuid, values).then((field) => {
      dispatch({type: actions.SET_FIELD, field});
    })
  }

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: model,
    collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        };
    },
    drop() {
      dropComplete()
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Get pixels to the top
      dragField(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: model,
    item: () => {
        return { uuid, index };
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
  });
  const style = isDragging ? styleDnD : styleNormal;
  const opacityDrag = isDragging ? 0 : 1;

  drag(drop(ref));

  const editableName = <Editable
    value={editableFieldName}
    onInput={(value) => setEditableFieldName(value)}
    finishEdit={() => updateFieldValues({name: editableFieldName})}
  />

  const editableType = <EditableChoice
    value={editableFieldType}
    choices={choices}
    onChange={(value) => setEditableFieldType(value)}
    finishEdit={() => updateFieldValues({type: editableFieldType})}
  />

  function getSuggestions(field_type) {
    if (field_type === 'CharField') {
      return [
        "max_length=10",
        "max_length=100",
        "max_length=255",
      ]
    }
    if (field_type === 'DecimalField') {
      return [
        "max_digits=5, decimal_places=2",
        "max_digits=19, decimal_places=10",
      ]
    }
    // TODO Other Field types
    return []
  }

  const editableArgs = <Editable
    value={editableFieldArgs}
    choices={choices}
    onInput={(value) => setEditableFieldArgs(value)}
    finishEdit={() => updateFieldValues({args: editableFieldArgs})}
    suggestions={getSuggestions(editableFieldType)}
  />

  const editableField = {
    name: editableName,
    type: editableType,
    args: editableArgs,
  }
  
  const doConfirmedDelete = () => {
    deleteField(jwt, dispatch, field);
  }

  return (
    <div ref={ref} style={{ ...style, opacityDrag }} data-handler-id={handlerId} onBlur={() => setDeleteVisibility("hidden")}>
      <div
        className="d-inline-block position-relative mr-5"
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
        role="button"
      >
        <ConfirmDelete
          title={`Delete '${field.name}'?`}
          style={{visibility: deleteVisibility, zIndex: 9999, position: "absolute"}}
          onConfirm={doConfirmedDelete}
          onCancel={() => setDeleteVisibility("hidden")}
        />

        <span role="button">
          <Trash
            role="button"
            onClick={() => setDeleteVisibility("visible")}
            color={icon_color}
            size={icon_height}
            className="position-absolute"
            style={{
              top: "50%",
              marginTop: (-icon_height/2) + "px",
              opacity: opacity,
            }}
          />
        </span>
        <span>
          {field_widget(editableField)}
        </span>
      </div>
    </div>
  )
}

export default withNamedStores(
  withDjangoBuilderNamedStores(
    Field, ['dispatch', 'fields']
  ), ['jwt', 'open_api']
);