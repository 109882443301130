import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Trash } from 'react-bootstrap-icons';
import { FaFolder, FaFolderOpen } from 'react-icons/fa';

import { withDjangoBuilderNamedStores } from './store/state';
import ConfirmDelete from './pages/ConfirmDelete';
import { get_app_files, get_template_files } from './rendering/files';
import { format_icon } from './rendering/icons';

const APP_PAGE = "app";

const AppModule = (props) => {

  console.debug("AppModule", props);
  
  const { project, app, apps, models, pageType, selectedApp, handleDeleteApp, appPage } = props;

  const appData = apps[app.uuid];
  const [hidden, setHidden] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState("hidden");
  const [hover, setHover ] = useState(false);

  const opacity = hover ? "100%" : "20%";

  const doConfirmedDelete = async () => {
    setDeleteVisibility("hidden");
    handleDeleteApp(app)
  }

  const fileLinkClass = (app, page) => {
    return pageType === APP_PAGE && app.uuid === selectedApp && page === appPage 
    ? "d-block active page_link text-decoration-none" : "d-block page_link text-decoration-none";
  }

  const templateLinkClass = (app, page) => {
    return fileLinkClass(app, page) + " ml-2";
  }

  const page_url = (page) => {
    return "/project/" + project.uuid + "/" + appData.module + "/" + page;
  }

  const child_display = hidden ? "ml-2 d-none" : "ml-2";
  const icon_display = hidden ? format_icon(<FaFolder />) : format_icon(<FaFolderOpen />);

  return <div key={app.uuid}>
    <div
      className="page_link"
      role="button"
      onMouseOver={() => setHover(true) }
      onMouseOut={() => setHover(false) }
    >
      <div role="button" onClick={() => setHidden(!hidden)}>{icon_display}{" "}{appData.module}
      <span className="d-none">({appData.uuid})</span>
      <span style={{position: "relative", float: "right"}}>
        <Trash
          onClick={() => setDeleteVisibility("visible")}
          color={"#000"}
          size={14}
          className="ml-2"
          style={{opacity: opacity}}
        />
        <ConfirmDelete
          title={"Delete " + appData.module + "?"}
          style={{
            visibility: deleteVisibility,
            zIndex: 9999,
            position: "absolute",
            whiteSpace: "nowrap",
            right: "0px",
            top: "0px",
          }}
          onConfirm={doConfirmedDelete}
          onCancel={() => setDeleteVisibility("hidden")}
        />
      </span>
      </div>
    </div>

    <div className={child_display} role="button">
      {
        get_app_files(project).map(({name, icon}) => {
          return <Link key={name} className={fileLinkClass(app, name)} to={page_url(name)}>
            { icon ? format_icon(icon) : "-" } {name}
            {name === "models.py" && <em className="text-muted"> ({appData.models.length})</em>}
          </Link>
        })
      }
      <div role="button"
        className={fileLinkClass(appData.module)}
        > {format_icon(<FaFolder />)} {"templates/" + appData.module}
      </div>
      {
        appData.models.map(model => {
          const modelData = models[model.uuid];
          return <div key={model.uuid}>
            {
              get_template_files(project).map(({name, icon}) => {
                const key = `${name} ${modelData.name}`
                const filepath = `templates/${modelData.name}/${name}`
                return <Link key={key} className={templateLinkClass(app, name)} to={page_url(filepath)}>
                  { icon ? format_icon(icon) : "-" } {modelData.name.toLowerCase()}_{name}
                </Link>
              })
            }
          </div>
        })
      }
    </div>
  </div>

};

export default withDjangoBuilderNamedStores(AppModule, ['apps', 'models']);