import { useState, useCallback, useEffect } from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import actions from '../../store/actions';
import Field from './Field.js';
import { updateField } from '../../requests'

function Fields(props) {

  console.debug('Fields', props);
  
  const { model, fields, dispatch, jwt } = props;

  const fields_length = Object.keys(fields).length;

  const modelFields = model.fields.map(f => fields[f.uuid]).sort(
    (field1, field2) => field1.order - field2.order
  );
  const [moveableFields, setMoveableFields] = useState(modelFields);

  useEffect(() => {
    if (model.fields.length !== moveableFields.length){
      const newModels = model.fields.map(f => fields[f.uuid]).sort(
        (field1, field2) => field1.order - field2.order
      );
      setMoveableFields(newModels);
    }
  }, [fields_length, fields, model.fields, moveableFields]);

  const updateFieldValues = useCallback((field, values) => {
    return updateField(jwt, field.uuid, values).then((field) => {
      dispatch({type: actions.SET_FIELD, field})
    })
  }, [dispatch, jwt]);

  const dropComplete = useCallback(() => {
    moveableFields.forEach((field, i) => {
      updateFieldValues(field, {order: i});
    })
  }, [moveableFields, updateFieldValues]);
  
  const dragField = useCallback((dragIndex, hoverIndex) => {
    const draggedField = moveableFields[dragIndex];
    setMoveableFields(
      update(moveableFields, {
        $splice: [ [dragIndex, 1], [hoverIndex, 0, draggedField],],
      })
    );
    
  }, [moveableFields, setMoveableFields]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        {moveableFields.map((field, i) => 
          <Field
            model={model.uuid}
            key={field.uuid}
            index={i}
            field={field}
            dragField={dragField}
            dropComplete={dropComplete}
          />
        )}
      </DndProvider>
    </div>
  )
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      Fields, ['dispatch', 'fields']
  ),['jwt']
);