import React, { useState } from 'react';
import { model_class, model_methods, model_meta } from '../../rendering/widgets'
import { Trash } from 'react-bootstrap-icons';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import NewField from '../Field/NewField.js';
import Fields from '../Field/Fields.js';
import NewRelationship from '../Relationship/NewRelationship';
import Relationships from '../Relationship/Relationships';
import Editable from '../../Editable';
import { updateModel, deleteModel } from '../../requests'
import actions from '../../store/actions';
import ConfirmDelete from '../../pages/ConfirmDelete';

function Model(props) {
  
  console.debug("Model:", props)
  
  const { model, apps, fields, models, jwt, dispatch } = props;

  const appData = apps[model.app]

  const [edittableModelName, setEdittableModelName] = useState(model.name);
  const [edittableModelParents, setEdittableModelParents] = useState(model.parents.join(", "));
  const [deleteVisibility, setDeleteVisibility] = useState("hidden");
  const [hover, setHover ] = useState(false);

  const icon_height = 14;
  const icon_color = '#000';
  const opacity = hover ? "100%" : "20%";

  const modelFieldIds = Object.keys(fields).filter(field => fields[field].model === model.uuid);
  const modelFields = modelFieldIds.map(fieldId => fields[fieldId]);

  const updateModelName = (name) => {
    updateModel(jwt, model.uuid, name).then((model) => {
      dispatch({type: actions.SET_MODEL, model});
    })
  }

  const updateModelParents = (parents_value) => {
    const parents = parents_value.split(",")
      .map(
        parent => parent.trim()
      ).filter(
        parent => parent !== ""
      );
    updateModel(jwt, model.uuid, {parents}).then((model) => {
      setEdittableModelParents(model.parents.join(", "));
      dispatch({type: actions.SET_MODEL, model});
    })
  }

  const doConfirmedDelete = () => {
    deleteModel(jwt, dispatch, model);
  }

  const edittableModelNameComponent = <Editable
    type="input"
    className="hljs-title"
    value={edittableModelName}
    onInput={(value) => setEdittableModelName(value)}
    finishEdit={() => updateModelName({name: edittableModelName})}
  />

  const users_models = Object.values(models).map(
    model => `${apps[model.app].module}.${model.name}`
  )

  const edittableModelParentsComponent = <Editable
    type="input"
    className="hljs-title"
    value={edittableModelParents}
    onInput={(value) => setEdittableModelParents(value)}
    finishEdit={() => updateModelParents(edittableModelParents)}
    suggestions={
      [
        ...users_models,
        "django.db.models.Model",
        "django.contrib.auth.models.AbstractUser",
        "django.contrib.auth.models.AbstractBaseUser",
      ]
    }
  />

  return (
    <div key={model.uuid}>
      <span
        role="button" 
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
      >

        {model_class(edittableModelNameComponent, edittableModelParentsComponent)}

        <span role="button" style={{position: "relative"}}>
          <Trash
            className="ml-2"
            onClick={() => setDeleteVisibility("visible")}
            color={icon_color}
            size={icon_height}
            style={{
              opacity: opacity,
            }}
            
        />
          <ConfirmDelete
            title={`Delete '${model.name}'?`}
            style={{visibility: deleteVisibility, zIndex: 9999, position: "absolute", left: "0px", top: "0px"}}
            onConfirm={doConfirmedDelete}
            onCancel={() => setDeleteVisibility("hidden")}
          />
        </span>
      </span>
      {"\n"}
      {"\n"}
      <Fields model={model} />
      <NewField model={model} />
      {"\n"}
      <Relationships model={model} />
      <NewRelationship model={model} />
      {"\n"}
      {model_meta()}
      {model_methods(appData, model, modelFields)}
      {"\n"}
    </div>
  )
}


export default withNamedStores(
    withDjangoBuilderNamedStores(
      Model, ['apps', 'models', 'fields', 'dispatch']
  ),['jwt']
);