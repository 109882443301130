import React from 'react';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import Model from './Model.js';

function Models(props) {
  
  console.debug("Models:", props)
  
  const { models } = props;

  const orderedModels = models.sort(
    (model1, model2) => model1.order - model2.order
  );

  return (
    <div>
      {
        orderedModels.map( model => {
          return <Model model={model} key={model.uuid} />
        })
      }
    </div>
  )
}

export default withNamedStores(
    withDjangoBuilderNamedStores(Models, ['dispatch']), ['jwt']
);