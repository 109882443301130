import { renderSettings } from "./django_settings";
import { renderURLs } from "./django_project_urls";
import { renderRouting } from "./django_project_routing";
import { renderFile } from "./file_renderer";

class ProjectCode {

  constructor(project, apps, models) {
    this.project = project;
    this.apps = apps;
    this.models = models;
  }

  async renderIndex() {
    const content = await renderFile(this.project, "templates/index.html.tmpl");

    const apps = Object.values(this.apps).map(app => {

      const model_links = Object.values(this.models).filter(m => m.app === app.uuid).map(model => {
        return `
<div class="m-4">
  <a class="btn btn-light" href="{% url '${app.module}-${model.name.toLowerCase()}-list' %}">
    ${model.name} Listing
  </a>
</div>`;
      })

      if (!model_links.length) {
        model_links.push(`<div class="m-2"> No models defined in ${app.name}</div>`);
      }

      return `<h6 class="m-2">${app.module}</h6>\n` + model_links.join("\n");
    })


    return content.replaceAll("{{model.links}}", apps.join("\n\n"));
  }

  page(page) {
    switch (page) {
      case "settings.py":
        return renderSettings(this.project, this.apps);
      case "urls.py":
        return renderURLs(this.project, this.apps);
      case "routing.py":
        return renderRouting(this.project, this.apps);
      case "templates/base.html":
        return renderFile(this.project, page + ".tmpl");
      case "templates/index.html":
        return this.renderIndex();
      default:
        try {
          return renderFile(this.project, page);
        } catch (e) {
          return `Page not found: ${page}`;
        }
    }
  }
}

export default ProjectCode;
