import { FaPython, FaMarkdown, FaFileAlt, FaHtml5 } from 'react-icons/fa';

const html = <FaHtml5 />
const markdown = <FaMarkdown />
const file_alt = <FaFileAlt />
const python = <FaPython />

const root_files = [
  {name: "README.md", icon: markdown },
  {name: "LICENSE", icon: file_alt},
  {name: "manage.py", icon: python},
  {name: "requirements.txt", poetry: false, icon: file_alt},
  {name: "pyproject.toml", poetry: true, icon: file_alt},
  {name: "templates/base.html", icon: html},
  {name: "templates/index.html", icon: html},
]

const matches_project = (value, project_value) => {
  return value !== undefined ? (value && project_value) || (!value && !project_value) : true
}

export const get_root_files = (project) => root_files.filter(
  ({poetry}) => matches_project(poetry, project.use_poetry)
)

const project_files = [
  {name: "settings.py", icon: python},
  {name: "wsgi.py", icon: python},
  {name: "urls.py", icon: python},
  {name: "asgi.py", channels: true, icon: python},
  {name: "routing.py", channels: true, icon: python},
  {name: "consumers.py", channels: true, icon: python},
];

export const get_project_files = (project) => project_files.filter(
  ({channels}) => matches_project(channels, project.use_channels)
)

const app_files = [
  {name: "models.py", icon: python},
  {name: "views.py", icon: python},
  {name: "forms.py", icon: python},
  {name: "admin.py", icon: python},
  {name: "urls.py", icon: python},
  {name: "serializers.py", rest_framework: true, icon: python},
  {name: "api.py", rest_framework: true, icon: python},
  {name: "consumers.py", channels: true, icon: python},
]

export const get_app_files = (project) => app_files.filter(
  ({channels}) => matches_project(channels, project.use_channels)
).filter(
  ({rest_framework}) => matches_project(rest_framework, project.use_rest_framework)
)


const app_test_files = [
  {name: "test_models.py", icon: python},
  {name: "test_views.py", icon: python},
]

export const get_app_test_files = (project) => app_test_files.filter(
  ({channels}) => matches_project(channels, project.use_channels)
).filter(
  ({rest_framework}) => matches_project(rest_framework, project.use_rest_framework)
)


const template_files = [
  {name: "list.html", icon: html},
  {name: "form.html", icon: html},
  {name: "detail.html", icon: html},
]

export const get_template_files = (project) => template_files