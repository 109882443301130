const template_cache = {};

export const load_template = async (template_name) => {
  if (template_cache[template_name]) {
    console.debug(`Loading template from cache ${template_name}`);
  } else {
    try {
      const template_url = require('./templates/' + template_name)
      const template_response = await fetch(template_url.default)
      template_cache[template_name] = await template_response.text();
      console.debug(`Loading template ${template_name} from url ${template_url.default}`);
    } catch (err) {
      console.error(`Error loading template ${template_name}`, err);
      return `Not Found - ${template_name}`
    }
  }
  return template_cache[template_name]
}