import React, { useState } from 'react';
import { field_widget } from '../../rendering/widgets'
import { PlusCircleFill } from 'react-bootstrap-icons';
import { addField } from '../../requests';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import { next_prefixed_name } from '../../utils.js';

const COLOR_ON = "red";
const COLOR_OFF = "gray";

function NewField(props) {
  
  console.debug("NewField:", props)

  const { jwt, dispatch, model, fields } = props;

  const existingFieldNames = Object.values(fields).filter(
    field => field.model === model.uuid
  ).reduce((acc, field) => [...acc, field.name], []);

  const otherModelFields = model.fields.length;
  
  const [hover, setHover ] = useState(false);

  const new_field_prefix = "field";

  const new_field_name = next_prefixed_name(new_field_prefix, existingFieldNames);
  
  const new_field = {name: new_field_name, type: "CharField" };

  const color = hover ? COLOR_ON : COLOR_OFF;
  const opacity = hover ? "100%" : "70%";
  const icon_color = hover ? "teal" : "lightgray";
  const icon_size = 14;
  const order = otherModelFields + 1;
  const type = 'CharField'

  const handleAddField = () => {
    addField(jwt, dispatch, { model: model.uuid, name: new_field_name, order, type});
  }

  return (
    <div>
      <div
        className="d-inline-block position-relative mr-5"
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
        onClick={() => handleAddField() }
        style={{opacity: opacity}}
        role="button"
      >
        <PlusCircleFill
          color={icon_color}
          size={icon_size}
          className="position-absolute"
          style={{
            top: "50%",
            marginTop: (-icon_size/2) + "px",
          }}
        />
        <span style={{color: color}}>
          {field_widget(new_field)}
        </span>
      </div>
    </div>
  )
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      NewField, ['dispatch', 'fields']
  ),['jwt']
);