
import actions from './actions';

const listToObjectByField = (listing, field) => {
  return listing.reduce(
    (accumulator, item) => (
      { ...accumulator, [item[field]]: item }
    ), {}
  )
}

const initialState = {
  projects: undefined,
  apps: undefined,
  models: undefined,
  fields: undefined,
};

const Reducer = (state, action) => {
  console.debug('reducer', action);
  switch (action.type) {
    case actions.SET_PROJECTS: {
      const { projects } = action;
      const projectsByID = listToObjectByField(projects, "uuid");
      return { ...state, projects: projectsByID };
    }
    case actions.SET_PROJECT:
    case actions.ADD_PROJECT: {
      const { project } = action;
      const { projects } = state;
      projects[project.uuid] = project;
      return { ...state, projects };
    }
    case actions.REMOVE_PROJECT: {
      const { uuid } = action;
      const { projects } = state;
      delete projects[uuid];
      return { ...state, projects};
    }
  
    case actions.SET_APPS: {
      const { apps } = action;
      const appsByID = listToObjectByField(apps, "uuid");
      return { ...state, apps: appsByID };
    }
    case actions.ADD_APP: {
      const { app } = action;
      const { project, uuid } = app;
      const { apps, projects } = state;
      const projectApps = projects[project].apps;
      const existing = projectApps.find(a => a.uuid === uuid);
      if (!existing) {
        projects[project].apps = [...projectApps, {uuid}];
      }
      apps[uuid] = app;
      return { ...state, apps, projects };
    }
    case actions.ADD_MODEL: {
      const { model } = action;
      const { app, uuid } = model;
      const { models, apps } = state;
      const appModels = apps[app].models
      const existing = appModels.find(m => m.uuid === uuid);
      if (!existing) {
        apps[app].models = [...appModels, {uuid}];
      }
      models[uuid] = model;
      return { ...state, models, apps};
    }
    case actions.REMOVE_APP: {
      const { uuid } = action;
      const { apps, projects } = state;
      const project = apps[uuid].project;
      delete apps[uuid];
      projects[project].apps = projects[project].apps.filter(a => a.uuid !== uuid);;
      return { ...state, apps, projects};
    }
  
    case actions.SET_MODELS: {
      const { models } = action;
      const modelsByID = listToObjectByField(models, "uuid");
      return { ...state, models: modelsByID };
    }
    case actions.SET_MODEL: {
      const { model } = action;
      const { uuid } = model;
      const { models } = state;
      models[uuid] = model;
      return { ...state, models };
    }
    case actions.REMOVE_MODEL: {
      const { model } = action;
      const { uuid, app } = model;
      const { models, apps } = state;
      delete models[uuid];
      apps[app].models = apps[app].models.filter(m => m.uuid !== uuid);
      return { ...state, models};
    }

    case actions.SET_FIELDS: {
      const { fields } = action;
      const fieldsByID = listToObjectByField(fields, "uuid");
      return { ...state, fields: fieldsByID };
    }
    case actions.SET_FIELD: {
      const { field } = action;
      const { fields } = state;
      fields[field.uuid] = field;
      return { ...state, fields };
    }
    case actions.REMOVE_FIELD: {
      const { field } = action;
      const { uuid, model } = field;
      const { models, fields } = state;
      delete fields[uuid];
      models[model].fields = models[model].fields.filter((f) => f.uuid !== uuid);
      return { ...state, models, fields};
    }
    case actions.ADD_FIELD: {
      const { field } = action;
      const { model, uuid } = field;
      const { fields, models } = state;
      const modelFields = models[model].fields
      const existing = modelFields.find(f => f.uuid === uuid);
      if (!existing) {
        models[model].fields = [...modelFields, {uuid}];
      }
      fields[uuid] = field;
      return { ...state, models, fields};
    }

    case actions.SET_RELATIONSHIPS: {
      const { relationships } = action;
      const relationshipsByID = listToObjectByField(relationships, "uuid");
      return { ...state, relationships: relationshipsByID };
    }
    case actions.SET_RELATIONSHIP: {
      const { relationship } = action;
      const { relationships } = state;
      relationships[relationship.uuid] = relationship;
      return { ...state, relationships };
    }
    case actions.REMOVE_RELATIONSHIP: {
      const { relationship } = action;
      const { uuid, model } = relationship;
      const { models, relationships } = state;
      delete relationships[uuid];
      models[model].relationships = models[model].relationships.filter((r) => r.uuid !== uuid);
      return { ...state, models, relationships};
    }
    case actions.ADD_RELATIONSHIP: {
      const { relationship } = action;
      const { model, uuid } = relationship;
      const { relationships, models } = state;
      const modelrelationships = models[model].relationships
      const existing = modelrelationships.find(r => r.uuid === uuid);
      if (!existing) {
        models[model].relationships = [...modelrelationships, {uuid}];
      }
      relationships[uuid] = relationship;
      return { ...state, models, relationships};
    }

    default:
      console.error('UNKNOWN ACTION', action.type, action);
      return state;
  }
};


export { Reducer, initialState };