
const SET_PROJECTS = 'SET_PROJECTS';
const SET_PROJECT = 'SET_PROJECT';
const ADD_PROJECT = 'ADD_PROJECT';
const REMOVE_PROJECT = 'REMOVE_PROJECT';

const SET_APPS = 'SET_APPS';
const ADD_APP = 'ADD_APP';
const REMOVE_APP = 'REMOVE_APP';

const SET_MODELS = 'SET_MODELS';
const SET_MODEL = 'SET_MODEL';
const ADD_MODEL = 'ADD_MODEL';
const REMOVE_MODEL = 'REMOVE_MODEL';

const SET_FIELDS = 'SET_FIELDS';
const SET_FIELD = 'SET_FIELD';
const ADD_FIELD = 'ADD_FIELD';
const REMOVE_FIELD = 'REMOVE_FIELD';

const SET_RELATIONSHIPS = 'SET_RELATIONSHIPS';
const SET_RELATIONSHIP = 'SET_RELATIONSHIP';
const ADD_RELATIONSHIP = 'ADD_RELATIONSHIP';
const REMOVE_RELATIONSHIP = 'REMOVE_RELATIONSHIP';

const actions = {
  SET_PROJECTS,
  SET_PROJECT,
  ADD_PROJECT,
  REMOVE_PROJECT,
  SET_APPS,
  ADD_APP,
  REMOVE_APP,
  SET_MODELS,
  SET_MODEL,
  ADD_MODEL,
  REMOVE_MODEL,
  SET_FIELDS,
  SET_FIELD,
  ADD_FIELD,
  REMOVE_FIELD,
  SET_RELATIONSHIPS,
  SET_RELATIONSHIP,
  ADD_RELATIONSHIP,
  REMOVE_RELATIONSHIP,
}

export default actions;