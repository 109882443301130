
import AppCode from "./AppCode";

export const field_widget = (field) => {
  return (
    <span key={field.uuid}>
      {"    "}{field.name}{" = models."}{field.type}{"("}{field.args}{")"}
    </span>
  );
}

export const relationship_widget = (rel) => {
  const comma = rel.args ? `, ` : "";
  return (
    <span key={rel.uuid}>
      {"    "}{rel.name}{" = models."}{rel.type}{"(\""}{rel.to}
      {"\""}{", on_delete=models."}{rel.on_delete}{comma}{rel.args}{")"}
    </span>
  );
}

export const model_fields = (fields) => {
  return fields.map(f => field_widget(f))
}

export const model_class = (name, parents) => {
  return <span className="hljs-class">
    <span className="hljs-keyword">class</span>{" "}
    {name}
    (<span className="hljs-params">
      {parents}
    </span>):
  </span>
}

export const model_meta = () => {
  return <>
    {"    "}
    <span className="hljs-keyword">class</span>
    {" "}
    <span className="hljs-title">Meta</span>:{"\n"}
    {"        pass"}
    {"\n\n"}
  </>
}

export const model_methods = (app, model, fields) => {

  const str_field = AppCode.model_str_field(fields)

  return <>
    {"    "}
    <span className="hljs-keyword">def</span>
    {" "}
    <span className="hljs-title function_">__str__</span>
    (<span className="hljs-params">self</span>){"\n"}
    {"        "}<span className="hljs-keyword">return</span>
    {" "}<span className="hljs-keyword">str</span>({"self."}{str_field})
    {"\n\n"}

    {"    "}
    <span className="hljs-keyword">def</span>
    {" "}
    <span className="hljs-title function_">get_absolute_url</span>
    (<span className="hljs-params">self</span>){"\n"}
    {"        "}<span className="hljs-keyword">return</span>
    {" "}<span className="hljs-keyword"></span>{"reverse("}
    {"\""}
    {app.module}
    {"-"}
    {model.name.toLowerCase()}
    {"-detail\""}
    {", args=(self.pk,)"}
    {")"}
    {"\n\n"}

    {"    "}
    <span className="hljs-keyword">def</span>
    {" "}
    <span className="hljs-title function_">get_update_url</span>
    (<span className="hljs-params">self</span>){"\n"}
    {"        "}<span className="hljs-keyword">return</span>
    {" "}<span className="hljs-keyword"></span>{"reverse("}
    {"\""}
    {app.module}
    {"-"}
    {model.name.toLowerCase()}
    {"-update\""}
    {", args=(self.pk,)"}
    {")"}
    {"\n\n"}

  </>
}

export const model_widget = (name, fields=[], relationships=[]) => {

  const fields_code = fields.map(field => field_widget(field));
  const relationship_code = relationships.map(relationship => relationship_widget(relationship));
  
  return <>
    {model_class(name)}
    <br/>
    { fields ? fields_code : ""}
    <br/>
    { relationships ? relationship_code : ""}
    <br/>
    {"    ..."}
    <br/>
    {"    ..."}
    <br/>
  </>;
}