
import Tar from 'tar-js'
import { get_app_files, get_project_files, get_root_files, get_template_files } from './files';
import AppCode from "./AppCode";
import ProjectCode from "./ProjectCode";
import ModelCode from './ModelCode';

class Tarball {

  constructor () {
    this.tarfile = new Tar()
  }

  uInt8ToString (buf) {
    var i, length, out = ''
    for (i = 0, length = buf.length; i < length; i += 1) {
        out += String.fromCharCode(buf[i])
    }
    return out
  }

  append (file_name, content) {
    this.tarfile.append(file_name, content)
  }

  get_url () {
    const uint8_str = this.uInt8ToString(this.tarfile.out);
    const base64 = Buffer.from(uint8_str, 'utf-8').toString('base64');
    return "data:application/tar;base64," + base64;
  }
}


export const getProjectTarball = async (projectData, apps, models, fields, relationships) => {
    const tarball = new Tarball()

    const project_code = new ProjectCode(projectData, apps, models)

    const code_promises = []

    const root_code_promises = get_root_files(projectData).map(async file => (
      new Promise(async (resolve) => (
        resolve({path: `${projectData.module}/${file.name}`, code: await project_code.page(file.name)})
      ))
    ))

    code_promises.push(...root_code_promises)

    const project_code_promises = get_project_files(projectData).map(async file => (
      new Promise(async (resolve) => (
        resolve({path: `${projectData.module}/${projectData.module}/${file.name}`, code: await project_code.page(file.name)})
      ))
    ))

    code_promises.push(...project_code_promises)

    projectData.apps.map(async app => {
      const appData = apps[app.uuid];
      const appModels = Object.values(appData.models).map(m => models[m.uuid])
      
      const modelFields = []
      appModels.forEach(model => {
        modelFields.push(...Object.values(model.fields).map(f => fields[f.uuid]))
      })

      const relationshipFields = []
      appModels.forEach(model => {
        relationshipFields.push(...Object.values(model.relationships).map(r => relationships[r.uuid]))
      })

      const app_code = new AppCode(projectData, appData, appModels, modelFields, relationshipFields)
      get_app_files(projectData).map(async file => {
        code_promises.push(new Promise(async (resolve) => {
          resolve({path: `${projectData.module}/${appData.module}/${file.name}`, code: app_code.page(file.name)})
        }))
      })

      appData.models.forEach(model => {

        const modelData = models[model.uuid];

        const model_fields = Object.values(modelData.fields).map(f => fields[f.uuid])

        const model_code = new ModelCode(projectData, appData, modelData, model_fields)

        get_template_files(projectData).map(async file => {
          code_promises.push(new Promise(async (resolve) => {
            const template_path = "templates/" + file.name + ".tmpl"
            const code = await model_code.page(template_path)
            resolve({
              path: `${projectData.module}/${appData.module}/templates/${appData.module}/${modelData.name.toLowerCase()}_${file.name}`,
              code: code
            })
          }))
        })
      })

      tarball.append(`${projectData.module}/${appData.module}/migrations/__init__.py`, "")
    })

    await Promise.all(code_promises).then(codeData => (
      codeData.forEach(codeData => {
        tarball.append(codeData.path, codeData.code)
      })
    ))

    return new Promise((resolve) => resolve(tarball))
}
