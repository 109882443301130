import { load_template } from './code';
import { render_template } from './file_renderer';

export const renderRouting = async (project, apps) => {

  const application_consumers = (app) => {
    return `from ${app.module}.consumers import ${app.module}Consumer`
  }

  const application_consumer_routes = (app) => {
    return `"${app.module}": ${app.module}Consumer,`
  }

  const routing_template = await load_template('routing.py')

  const routing = await render_template(project, routing_template);

  return routing
    .replaceAll(
      '{{application_consumers}}',
      Object.values(apps).map(application_consumers).join('\n')
    ).replaceAll(
      '{{application_consumer_routes}}',
      Object.values(apps).map(application_consumer_routes).join('\n        ')
    )
};