import React, { useState, useEffect } from 'react';
import { withNamedStores } from '../store/state';
import { fetchAll } from './requests'
import { withDjangoBuilderNamedStores } from './store/state'

import { Container, Row, Col, Spinner } from 'react-bootstrap';


function LoadingWrapper(props) {
  const { dispatch, jwt } = props;
  
  const [loading, setLoading] = useState(null);
  
  useEffect(() => {
    if (loading == null) {
      setLoading(true)
      fetchAll(jwt, dispatch).then(() => {
        setLoading(false);
      });
    }
  }, [loading, dispatch, jwt]);

  if (loading === null || loading === true) {
    return <>
      <Container fluid  className="mt-4">
        <Row>
          <Col>
            <div className="text-center">
              <div className="display-4">
                Welcome to DjangoBuilder
              </div>
              <Spinner animation="border" role="status" className="mt-4">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  }
  
  return <>{props.children}</>
}

export default withNamedStores(
  withDjangoBuilderNamedStores(LoadingWrapper, ['dispatch']),['jwt']
);