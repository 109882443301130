import { load_template } from './code';
import { render_template } from './file_renderer';

export const renderURLs = async (project, apps) => {

  const appToURLs = (app) => {
    return `path('${app.module}/', include('${app.module}.urls')),`;
  }

  const urls_template = await load_template('urls.py')

  const urls = await render_template(project, urls_template);

  return urls
    .replaceAll(
    '{{application_urls}}',
    Object.values(apps).map(appToURLs).join('\n    ')
  )
};