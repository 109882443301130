class AppCode {

  constructor(project, app, models, fields, relationships) {
    this.project = project;
    this.app = app;
    this.models = models;
    this.fields = fields;
    this.relationships = relationships;
  }

  page(page) {
    switch (page) {
      case "models.py":
        return this.models_code();
      case "views.py":
        return this.views_code();
      case "forms.py":
        return this.forms_code();
      case "serializers.py":
        return this.serializer_code();
      case "admin.py":
        return this.admin_code();
      case "urls.py":
        return this.urls_code();
      case "api.py":
        return this.api_code();
      case "consumers.py":
        return this.consumers_code();
      case "test_models.py":
        return this.test_models_code();
      case "test_views.py":
        return this.test_views_code();
      default:
        throw Error(`Page not found: ${page}`);
    }
  }

  model_imports() {
    return `from django.contrib.auth import get_user_model
from django.db import models
from django.urls import reverse
`;
  }

  static model_str_field(fields) {
    let str_field = fields.find((field) => field.name === "name");
    str_field = str_field ? null : fields.find((field) => field.type === "CharField");
    return str_field ? str_field.name : "pk";
  }

  models_code() {
    
    const modelsCode = this.models.map((model) => {
      const str_field = AppCode.model_str_field(this.fields)

      const fields_code = this.fields.filter(
        (field) => field.model === model.uuid
      ).map(field => {
        return `    ${field.name} = models.${field.type}(${field.args})`;
      });

      const relationship_code = this.relationships.filter(
        (rel) => rel.model === model.uuid
      ).map(rel => {
        const comma = rel.args ? ", " : "";
        return `    ${rel.name} = models.${rel.type}("${rel.to}", on_delete=models.${rel.on_delete}${comma}${rel.args})`;
      });

      return `class ${model.name}(models.Model):
${fields_code.join('\n')}

${relationship_code.join('\n')}

    class Meta:
        pass

    def __str__(self):
        return str(self.${str_field})

    def get_absolute_url(self):
        return reverse("${this.app.module}-${model.name.toLowerCase()}-detail", args=(self.pk,))

    def get_update_url(self):
        return reverse("${this.app.module}-${model.name.toLowerCase()}-update", args=(self.pk,))

`;
    });
    return `${this.model_imports()}\n
${modelsCode.join('\n\n')}
`;
  }

  api_imports() {
    return `from rest_framework import viewsets, permissions

from . import serializers
from . import models
`;
  }

  api_code() {

    const api_definitions = this.models.map((model) => {
      return `
class ${model.name}ViewSet(viewsets.ModelViewSet):
    """ViewSet for the ${model.name} class"""

    queryset = models.${model.name}.objects.all()
    serializer_class = serializers.${model.name}Serializer
    permission_classes = [permissions.IsAuthenticated]
`;
    });

    return `${this.api_imports()}\n${api_definitions.join('\n')}\n\n`;
  }

  consumers_imports() {
    return `from channels.consumer import SyncConsumer`;
  }

  consumers_code() {
    const consumer_definition = `
class ${this.app.module}Consumer(SyncConsumer):

    def ${this.app.module}_message(self, message):
        # do something with message
        pass
`

    return `${this.consumers_imports()}\n\n${consumer_definition}\n\n`;
  }

  serializer_imports() {
    return `from rest_framework import serializers

from . import models
`;
  }

  serializer_code() {

    const serializer_definitions = this.models.map((model) => {
      return `
class ${model.name}Serializer(serializers.ModelSerializer):

    class Meta:
        model = models.${model.name}
`;
    });

    return `${this.serializer_imports()}\n${serializer_definitions.join('\n')}\n\n`;
  }


  views_code() {
    return this.project.view_type === "class" ? this.views_code_class_based() : this.views_code_function_based();
  }

  view_imports_function_based() {
    return `from django.shortcuts import get_object_or_404, render, reverse
from django.http import HttpResponseRedirect

from . import models
from . import forms

`;
  }

  views_code_function_based() {

    const view_definitions = this.models.map((model) => {
      const model_lower = model.name.toLowerCase();
      return `
def ${model_lower}_detail(request, pk):
    ${model_lower} = get_object_or_404(models.${model.name}, pk=pk)
    return render(request, '${this.app.module}/${model_lower}_detail.html', {'object': ${model_lower}})


def ${model_lower}_list(request):
    ${model_lower}s = models.${model.name}.objects.all()
    return render(request, '${this.app.module}/${model_lower}_list.html', {'object_list': ${model_lower}s})


def ${model_lower}_create(request):
    template_name = '${this.app.module}/${model_lower}_form.html'
    form_class = forms.${model.name}CreateForm
  
    if request.method == 'POST':
        form = form_class(request.POST)
        if form.is_valid():
            form.save()
            return HttpResponseRedirect(reverse('${this.app.module}-${model_lower}-list'))
    else:
        form = form_class()
  
    return render(request, template_name, {'form': form})


def ${model_lower}_update(request, pk):
    template_name = '${this.app.module}/${model_lower}_form.html'
    form_class = forms.${model.name}UpdateForm
    ${model_lower} = get_object_or_404(models.${model.name}, pk=pk)
  
    if request.method == 'POST':
        form = form_class(request.POST, instance=${model_lower})
        if form.is_valid():
            form.save()
            return HttpResponseRedirect(reverse('${this.app.module}-${model_lower}-list'))
    else:
        form = form_class(instance=${model_lower})
    
    return render(request, template_name, {'form': form})
`;
    });


    return `${this.view_imports_function_based()}${view_definitions.join('\n')}
`;
  }

  view_imports_class_based() {
    return `from django.views import generic
from . import models
from . import forms

`;
  }

  views_code_class_based() {

    const view_definitions = this.models.map((model) => {
      return `
class ${model.name}View(generic.ListView):
    model = models.${model.name}
    form_class = forms.${model.name}CreateForm


class ${model.name}CreateView(generic.CreateView):
    model = models.${model.name}
    form_class = forms.${model.name}CreateForm


class ${model.name}DetailView(generic.DetailView):
    model = models.${model.name}
    form_class = forms.${model.name}UpdateForm


class ${model.name}UpdateView(generic.UpdateView):
    model = models.${model.name}
    form_class = forms.${model.name}UpdateForm
    pk_url_kwarg = "pk"
    `;
    });

    return `${this.view_imports_class_based()}${view_definitions.join('\n')}
`;
  }

  urls_imports() {
    return `from django.urls import path, include
from rest_framework import routers

from . import api
from . import views
`;
  }

  urls_definitions_class_based() {
    return this.models.map((model) => {
      const model_lower = model.name.toLowerCase();
      return `
    path('${model_lower}/', views.${model.name}View.as_view(), name='${this.app.module}-${model_lower}-list'),
    path('${model_lower}/create/', views.${model.name}CreateView.as_view(), name='${this.app.module}-${model_lower}-create'),
    path('${model_lower}/<int:pk>/', views.${model.name}DetailView.as_view(), name='${this.app.module}-${model_lower}-detail'),
    path('${model_lower}/<int:pk>/update/', views.${model.name}UpdateView.as_view(), name='${this.app.module}-${model_lower}-update'),
`;
    });
  }

  urls_definitions_function_based() {
    return this.models.map((model) => {
      const model_lower = model.name.toLowerCase();
      return `
    path('${model_lower}/', views.${model_lower}_list, name='${this.app.module}-${model_lower}-list'),
    path('${model_lower}/create/', views.${model_lower}_create, name='${this.app.module}-${model_lower}-create'),
    path('${model_lower}/<int:pk>/', views.${model_lower}_detail, name='${this.app.module}-${model_lower}-detail'),
    path('${model_lower}/<int:pk>/update/', views.${model_lower}_update, name='${this.app.module}-${model_lower}-update'),
`;
    })
  }

  urls_code() {
    const urls_definitions = this.project.view_type === "class" ? this.urls_definitions_class_based() : this.urls_definitions_function_based();

    const router_definitions = this.models.map((model) => {
      return `router.register("${model.name}", api.${model.name}ViewSet)`;
    });

    return `${this.urls_imports()}

router = routers.DefaultRouter()
${router_definitions.join('\n')}

urlpatterns = (
    path("api/v1/", include(router.urls)),
${urls_definitions.join('')})\n\n`;
  }

  forms_imports() {
    return `from django import forms
from . import models
`;
  }

  forms_code() {

    const form_definitions = this.models.map((model) => {
      return `
class ${model.name}CreateForm(forms.ModelForm):
    
    class Meta:
        model = models.${model.name}
        fields = '__all__'


class ${model.name}UpdateForm(forms.ModelForm):
    
    class Meta:
        model = models.${model.name}
        fields = '__all__'
`;
    });

    return `${this.forms_imports()}\n${form_definitions.join('\n')}\n\n`;
  }

  admin_imports() {
    return `from django.contrib import admin
from django import forms

from . import models
`;
  }

  admin_code() {

    const admin_definitions = this.models.map((model) => {
      return `
class ${model.name}AdminForm(forms.ModelForm):
    class Meta:
        model = models.${model.name}
        fields = '__all__'


@admin.register(models.${model.name})
class ${model.name}Admin(admin.ModelAdmin):
    form = ${model.name}AdminForm
`;
    });

    return `${this.admin_imports()}\n${admin_definitions.join('\n')}\n\n`;
  }

  
  test_models_code() {

    const test_model_imports = `import pytest
from ${this.app.module} import models

`
const test_models_fixtures = this.models.map((model) => {
  return `

@pytest.fixture(name="${model.name.toLowerCase()}")
def ${model.name}_fixture():
    model = models.${model.name}(name="test_${model.name}")
    model.save()`;
});

    const test_models_definitions = this.models.map((model) => {
      return `

@pytest.mark.django_db
def test_${model.name}(${model.name.toLowerCase()}):
    assert ${model.name}
    # Test ${model.name} here ...`;
    });

    return `${test_model_imports}${test_models_fixtures.join('\n')}\n${test_models_definitions.join('\n')}\n\n`;
  }

  test_views_code() {

    const test_model_imports = `import pytest
from django.urls import reverse
from django.test import Client
from ${this.app.module} import models


@pytest.fixture(name="client")
def client_fixture():
    return Client()
`

    const test_models_definitions = this.models.map((model) => {
      return `

@pytest.mark.django_db
def test_${model.name}(client):
    name = "test_${model.name}"
    model = models.${model.name}(name=name)
    model.save()
    url = reverse('${this.app.module}-${model.name}-list')
    response = self.client.get(url)
    assert response.status_code == 200
    assert model.name in response.content.decode("utf-8")`;
    });

    return `${test_model_imports}${test_models_definitions.join('\n')}\n\n`;
  }


}

export default AppCode;