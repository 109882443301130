import React, { useState } from 'react';
import { PlusCircleFill } from 'react-bootstrap-icons';
import { relationship_widget } from '../../rendering/widgets'
import { addRelationship } from '../../requests';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import { next_prefixed_name } from '../../utils.js';

const COLOR_ON = "red";
const COLOR_OFF = "gray";

function NewRelationship(props) {
  
  console.debug("NewRelationship:", props)

  const { jwt, dispatch, model, relationships } = props;

  const existingRelationshipNames = Object.values(relationships).filter(
    relationship => relationship.model === model.uuid
  ).reduce((acc, relationship) => [...acc, relationship.name], []);

  const otherModelRelationShips = model.fields.length;
  
  const [hover, setHover ] = useState(false);

  const new_relationship_prefix = "owner";

  const new_relationship_name = next_prefixed_name(new_relationship_prefix, existingRelationshipNames);
  
  const new_relationship = {
    name: new_relationship_name, type: "ForeignKey", to: "auth.User", args: "", on_delete: "CASCADE",
  };

  const color = hover ? COLOR_ON : COLOR_OFF;
  const opacity = hover ? "100%" : "70%";
  const icon_color = hover ? "teal" : "lightgray";
  const icon_size = 14;
  const order = otherModelRelationShips + 1;
  const type = 'ForeignKey'

  const handleAddRelationship = () => {
    addRelationship(jwt, dispatch, 
      {
        model: model.uuid, name: new_relationship_name, order, type, to: "auth.User", on_delete: "CASCADE",
      });
  }

  return (
    <div>
      <div
        className="d-inline-block position-relative mr-5"
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
        onClick={() => handleAddRelationship() }
        style={{opacity: opacity}}
        role="button"
      >
        <PlusCircleFill
          color={icon_color}
          size={icon_size}
          className="position-absolute"
          style={{
            top: "50%",
            marginTop: (-icon_size/2) + "px",
          }}
        />
        <span style={{color: color}}>
          {relationship_widget(new_relationship)}
        </span>
      </div>
    </div>
  )
}


export default withNamedStores(
    withDjangoBuilderNamedStores(
      NewRelationship, ['dispatch', 'relationships']
  ),['jwt']
);