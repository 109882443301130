import React, { useState } from 'react';
import { withNamedStores } from '../../store/state';
import { useParams, Link } from "react-router-dom";
import { withDjangoBuilderNamedStores } from '../store/state'
import { PlusCircleFill, CloudDownloadFill } from 'react-bootstrap-icons';
import { FaFolder, FaFolderOpen } from 'react-icons/fa';
import Editable from '../Editable';
import AppModule from '../AppModule';

import { updateProject, addApp, deleteApp } from '../requests'
import actions from '../store/actions';
import AppPage from "./AppPage"
import ProjectPage from "./ProjectPage"

import { Container, Row, Col } from 'react-bootstrap';
import ProjectConfig from './ProjectConfig';

import './Project.css';
import 'highlight.js/styles/atom-one-light.css'
import { next_prefixed_name } from '../utils';
import { getProjectTarball } from '../rendering/download';
import { get_root_files, get_project_files, get_app_test_files } from '../rendering/files';
import { format_icon } from '../rendering/icons';

const APP_PAGE = "APP_PAGE";
const PROJECT_PAGE = "PROJECT_PAGE";
const MODEL_PAGE = "MODEL_PAGE";
const CONFIG_PAGE = "CONFIG_PAGE";


function Project(props) {

  console.debug("Project:", props)
  const { projectid, page } = useParams();
  
  const { projects, apps, models, fields, relationships, jwt, dispatch } = props;
  const projectData = projects ? projects[projectid] : {name:""}

  const [projectHidden, setProjectHidden] = useState(false);
  const project_child_display = projectHidden ? "ml-2 d-none" : "ml-2";
  const project_icon_display = projectHidden ? format_icon(<FaFolder />) : format_icon(<FaFolderOpen />);

  const [testsHidden, setTestsHidden] = useState(false);
  const tests_child_display = testsHidden ? "ml-2 d-none" : "ml-2";
  const tests_icon_display = testsHidden ? format_icon(<FaFolder />) : format_icon(<FaFolderOpen />);

  const [edittableProject, setEdittableProject] = useState(projectData.name);
  const [edittableProjectModule, setEdittableProjectModule] = useState(projectData.module);

  let found_component = null;
  let found_page = "settings.py"
  let display_path = "settings.py"
  let pageType = PROJECT_PAGE
  let selectedApp;
  let selectedModel;

  if (page === "config") {
    pageType = CONFIG_PAGE
    found_component = <ProjectConfig projectData={projectData} />
    found_page = null
  } else if (page) {
    const page_split = page.split("/")
    const module = page_split.shift()
    found_page = page_split.join("/")
    display_path = page_split.join("/")
    if (module === projectData.module) {
      pageType = PROJECT_PAGE
    } else {
      const app = Object.values(apps).find(a => a.module === module)
      if (app) {
        selectedApp = app.uuid
        pageType = APP_PAGE
      }
      
      const next_path = page_split.shift()

      if (next_path === "templates") {
        const model_name = page_split.shift()
        const model = Object.values(models).find(m => m.name === model_name)
        if (model) {
          selectedModel = model;
          pageType = MODEL_PAGE
          // TODO - Move this path to files.js
          found_page = "templates/" + page_split.join("/") + ".tmpl"
          display_path = "templates /" + app.module + " / " + selectedModel.name.toLowerCase() + "_" + page_split.join("/")
        }
      }
    }
  } else {
    const [firstApp, ] = projectData ? projectData.apps : [];
    if (firstApp) {
      found_page = "models.py"
      display_path = "models.py"
      pageType = APP_PAGE
      selectedApp = firstApp.uuid
    }
  }

  const appData = apps[selectedApp]

  const updateProjectValues = (values) => {
    updateProject(jwt, projectid, values).then((project) => {
      dispatch({type: actions.SET_PROJECT, project});
    })
  }

  const existingAppNames = Object.values(apps).filter(
    app => app.project === projectid
  ).reduce((acc, app) => [...acc, app.module], []);

  const new_module = next_prefixed_name("app", existingAppNames);

  const handleDownloadProject = async () => {
    const tarball = await getProjectTarball(projectData, apps, models, fields, relationships)
    const url = tarball.get_url();
    const link = document.createElement("a")
    link.download = projectData.module + '.tar'
    link.href = url
    document.body.appendChild(link)
    link.click()
    return url
  }
  
  const handleAddApp = () => {
    addApp(jwt, dispatch, { project: projectid, name: "app", module: new_module })
  }

  const handleDeleteApp = async (app) => {
    await deleteApp(jwt, dispatch, app.uuid);
  }

  const editableProjectName = <Editable
    type="input"
    value={edittableProject}
    onInput={(value) => setEdittableProject(value)}
    finishEdit={() => updateProjectValues({name: edittableProject})}
  />

  const editableProjectModule = <Editable
    type="input"
    value={edittableProjectModule}
    onInput={(value) => setEdittableProjectModule(value)}
    finishEdit={() => updateProjectValues({module: edittableProjectModule})}
  />

  const linkClass = (page) => {
    const activeClass = pageType === PROJECT_PAGE && page === found_page ? "active" : "";
    return "d-block page_link text-decoration-none " + activeClass;
  }


  return (
    <Container fluid className="pt-2">
      <Row >
        <Col lg={{span:2, offset:2}} md={3} className="pt-2 pb-5 border-left border-top border-bottom">
          <h5 className="pb-2 mb-0 border-bottom">{editableProjectName}</h5>
          <code>
            <div className="p-2 mb-2 border-bottom" role="button">
              <div className="page_link mb-2" onClick={() => handleDownloadProject()}>
                <CloudDownloadFill color={"gray"} size={14} />
                <span> Download Project</span>
              </div>
              <Link
                to={"/project/" + projectid + "/config"}
                > <CloudDownloadFill color={"gray"} size={14} /> Project Config
              </Link>
            </div>
            {
              get_root_files(projectData).map(({name, icon}) => (
                <Link
                  key={name}
                  className={linkClass(name)}
                  to={"/project/" + projectid + "/" + projectData.module + "/" + name}
                  > { icon ? format_icon(icon) : "-" } {name}
                </Link>
              ))
            }
            <div role="button" onClick={() => setProjectHidden(!projectHidden)}>
              {project_icon_display}{" "}{projectData.module}
            </div>
            <div>
              <div className={project_child_display} role="button">
                {
                  get_project_files(projectData).map(({name, icon}) => (
                    <Link
                      key={name}
                      className={linkClass(name)}
                      to={"/project/" + projectid + "/" + projectData.module + "/" + name}
                    > { icon ? format_icon(icon) : "-" } {name}
                    </Link>
                  ))
                }
              </div>
              {
                projectData.apps.map((app) => {
                  return <AppModule
                    project={projectData}
                    key={app.uuid}
                    app={app}
                    pageType={pageType}
                    appPage={found_page}
                    selectedApp={selectedApp}
                    handleDeleteApp={handleDeleteApp}
                  />
                })
              }
              <div role="button" onClick={() => setTestsHidden(!testsHidden)}>
                {tests_icon_display} tests
              </div>
              <div className={tests_child_display}>
                {
                  projectData.apps.map((app) => {
                    const appData = apps[app.uuid]
                    return <div key={app.uuid}>
                    <div className="ml-2" role="button">
                      {format_icon(<FaFolderOpen />)} {appData.module}
                    </div>
                    {
                      get_app_test_files(projectData).map(({name, icon}) => {
                        return <Link
                          key={name}
                          className={linkClass(name) + " ml-4"}
                          to={"/project/" + projectid + "/" + appData.module + "/" + name}
                        >  { icon ? format_icon(icon) : "-" } {name}
                        </Link>
                      })
                    }
                    </div>
                  })
                }
              </div>
            </div>
            <div className="p-2 my-2 border-bottom border-top" role="button">
              <div className="page_link" onClick={() => handleAddApp()}>
                <PlusCircleFill color={"teal"} size={14} />
                <span> Add App</span>
              </div>
            </div>
          </code>
        </Col>

        <Col xl={6} lg={7} md={9} className="pt-2 border">
          {
            found_component
          }
          {
            pageType === PROJECT_PAGE ?
            <>
              <div className="mb-4">
                <em>{editableProjectModule} / {display_path}</em>
              </div>
              <ProjectPage projectData={projectData} page={found_page} />
            </>
            :<></>
          }
          {
            pageType === APP_PAGE ?
              <>
                <div className="mb-4">
                  <em>{editableProjectModule} / {appData?.module} / {display_path}</em>
                </div>
                <AppPage projectData={projectData} app={appData} page={found_page} />
              </>
            :<></>
          }
          {
            pageType === MODEL_PAGE ?
              <>
                <div className="mb-4">
                  <em>{editableProjectModule} / {appData?.module} / {display_path}</em>
                </div>
                <AppPage projectData={projectData} app={appData} model={selectedModel} page={found_page} />
              </>
            :<></>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
      Project, ['projects', 'apps', 'models', 'fields', 'relationships', 'dispatch']
  ),['user', 'jwt']
);