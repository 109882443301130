import { load_template } from './code';
import { camelCase } from 'lodash';

function capitalize(word) {
  return word[0].toUpperCase() + word.substring(1);
}

export const render_template = async (project, content, app, model) => {
  let rendered_content = content
    .replaceAll('{{project.name}}', project.name)
    .replaceAll('{{project.name_camelcase}}', capitalize(camelCase(project.module)))
    .replaceAll('{{project.module}}', project.module)
    .replaceAll('{{project.description}}', project.description)
  if (app) {
    rendered_content = rendered_content
      .replaceAll('{{app.name}}', app.name)
      .replaceAll('{{app.module}}', app.module);
  }
  if (model) {
    rendered_content = rendered_content
      .replaceAll('{{model.name}}', model.name)
      .replaceAll('{{model.name_lower}}', model.name.toLowerCase());
  }
  return rendered_content
};

export const renderFile = async (project, template, app, model) => {
  const content = await load_template(template)
  return render_template(project, content, app, model)
};

