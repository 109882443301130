import { load_template } from './code';
import { render_template } from './file_renderer';
import { randomFillSync  } from 'crypto';

const getRandomString = (length) => {
  const buf = Buffer.alloc(length);
  return randomFillSync(buf).toString('hex');
}

export const renderSettings = async (project, apps) => {

  const localStorageSecretKey = 'django_builder_secret_key';
  let secret_key = localStorage.getItem(localStorageSecretKey)

  if (!secret_key) {
    secret_key = getRandomString(32)
    localStorage.setItem(localStorageSecretKey, secret_key)
  }

  const settings_template = await load_template('settings.py')

  const settings = await render_template(project, settings_template);

  return settings
    .replaceAll('{{secret_key}}', secret_key)
    .replaceAll(
    '{{installed_apps}}',
    Object.values(apps).map(app => `'${app.module}',`).join('\n    ')
  )
};