import { renderFile } from "./file_renderer";

class ModelCode {

  constructor(project, app, model, fields) {
    this.project = project;
    this.app = app;
    this.model = model;
    this.fields = fields;
  }

  async renderDetails(content) {

    let field_table = ""

    this.fields.forEach(field => {
      field_table += `<tr>
          <td>${field.name}</td>
          <td>{{object.${field.name}}}</td>
        </tr>`
    })

    return content.replaceAll("{{field_table}}", field_table);
  }

  async page(page)  {
    switch (page) {
      case "templates/detail.html.tmpl":
        const content = await renderFile(this.project, page, this.app, this.model)
        return this.renderDetails(content)
      default:
        try {
          return renderFile(this.project, page, this.app, this.model);
        } catch (e) {
          return Promise.resolve(`Page not found: ${page}`);
        }
    }
  }
}


export default ModelCode;