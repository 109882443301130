import { getJSON, postJSON, deleteJSON, patchJSON} from '../api/requests';

import actions from './store/actions';

import { fetchToken, loadUser } from '../api/requests';
import { user_url } from '../api/urls';

const PROJECTS_URL = "/api/projects/";
const APPS_URL = "/api/apps/";
const MODELS_URL = "/api/models/";
const FIELDS_URL = "/api/fields/";
const RELATIONSHIPS_URL = "/api/relationships/";

const anonymous_url = '/app/users/anonymous/';

function auth_header(jwt) {
  return { 'Authorization': `Bearer ${jwt}` }
}

async function fetchURL(url, jwt, error_message) {
  try {
    const data = await getJSON(
      url, { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log(error_message, error);
    return Promise.reject(error);
  }
}

async function anonymousLogin(dispatch) {
  try {
    const token = await fetchToken(user_url);
    const data = await postJSON(anonymous_url, { }, { headers: { 'X-CSRFToken': token } })
    loadUser(dispatch, data.user, data.logout_url, data.token, data.jwt)
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not log in', error);
    return Promise.reject(error);
  }
}

async function fetchProjects(jwt) {
  const { items: projects } = await fetchURL(PROJECTS_URL, jwt, 'Could not fetch projects');
  return projects
}

async function fetchApps(jwt) {
  const { items: apps } = await fetchURL(APPS_URL, jwt, 'Could not fetch apps');
  return apps
}

async function fetchModels(jwt) {
  const { items: apps } = await fetchURL(MODELS_URL, jwt, 'Could not fetch apps');
  return apps
}

async function fetchFields(jwt) {
  const { items: apps } = await fetchURL(FIELDS_URL, jwt, 'Could not fetch apps');
  return apps
}

async function fetchRelationships(jwt) {
  const { items: apps } = await fetchURL(RELATIONSHIPS_URL, jwt, 'Could not fetch relationships');
  return apps
}

async function createProject(jwt, name, module, description) {
  try {
    const data = await postJSON(
      PROJECTS_URL,
      { name, module, description },
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not create project', error);
    return Promise.reject(error);
  }
}

async function addApp(jwt, dispatch, appData) {
  try {
    const app = await postJSON(
      APPS_URL, appData, { headers: auth_header(jwt) },
    )
    dispatch({type: actions.ADD_APP, app});
    return Promise.resolve(app);
  } catch (error) {
    console.log('Could not add app', error);
    return Promise.reject(error);
  }
}

async function addModel(jwt, dispatch, modelData) {
  try {
    const model = await postJSON(
      MODELS_URL, modelData, { headers: auth_header(jwt) },
    )
    dispatch({type: actions.ADD_MODEL, model});
    return Promise.resolve(model);
  } catch (error) {
    console.log('Could not add model', error);
    return Promise.reject(error);
  }
}

async function addField(jwt, dispatch, fieldData) {
  try {
    const field = await postJSON(
      FIELDS_URL, fieldData, { headers: auth_header(jwt) },
    )
    dispatch({type: actions.ADD_FIELD, field});
    return Promise.resolve(field);
  } catch (error) {
    console.log('Could not add field', error);
    return Promise.reject(error);
  }
}

async function addRelationship(jwt, dispatch, relationshipData) {
  try {
    const relationship = await postJSON(
      RELATIONSHIPS_URL, relationshipData, { headers: auth_header(jwt) },
    )
    dispatch({type: actions.ADD_RELATIONSHIP, relationship});
    return Promise.resolve(relationship);
  } catch (error) {
    console.log('Could not add relationship', error);
    return Promise.reject(error);
  }
}

async function updateProject(jwt, id, projectData) {
  try {
    const data = await patchJSON(
      `${PROJECTS_URL}${id}/`,
      projectData,
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not update project', error);
    return Promise.reject(error);
  }
}

async function updateModel(jwt, id, modelData) {
  try {
    const data = await patchJSON(
      `${MODELS_URL}${id}/`,
      modelData,
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not update model', error);
    return Promise.reject(error);
  }
}

async function updateField(jwt, id, fieldData) {
  try {
    const data = await patchJSON(
      `${FIELDS_URL}${id}/`,
      fieldData,
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not update field', error);
    return Promise.reject(error);
  }
}

async function updateRelationship(jwt, id, relationshipData) {
  try {
    const data = await patchJSON(
      `${RELATIONSHIPS_URL}${id}/`,
      relationshipData,
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not update relationship', error);
    return Promise.reject(error);
  }
}

async function deleteURL(url, jwt) {
  try {
    const data = await deleteJSON(
      url,
      { headers: auth_header(jwt) },
    )
    return Promise.resolve(data);
  } catch (error) {
    console.log('Could not delete project', error);
    return Promise.reject(error);
  }
}

async function deleteProject(jwt, dispatch, uuid) {
  await deleteURL(`${PROJECTS_URL}${uuid}/`, jwt)
  await dispatch({type: actions.REMOVE_PROJECT, uuid});
}

async function deleteApp(jwt, dispatch, uuid) {
  await deleteURL(`${APPS_URL}${uuid}/`, jwt);
  await dispatch({type: actions.REMOVE_APP, uuid});
}

async function deleteModel(jwt, dispatch, model) {
  await deleteURL(`${MODELS_URL}${model.uuid}/`, jwt);
  await dispatch({type: actions.REMOVE_MODEL, model});
}

async function deleteField(jwt, dispatch, field) {
  await deleteURL(`${FIELDS_URL}${field.uuid}/`, jwt);
  await dispatch({type: actions.REMOVE_FIELD, field});
}

async function deleteRelationship(jwt, dispatch, relationship) {
  await deleteURL(`${RELATIONSHIPS_URL}${relationship.uuid}/`, jwt);
  await dispatch({type: actions.REMOVE_RELATIONSHIP, relationship});
}

async function fetchAll(jwt, dispatch) {
  return Promise.all([
    fetchProjects(jwt),
    fetchApps(jwt),
    fetchModels(jwt),
    fetchFields(jwt),
    fetchRelationships(jwt),
  ]).then((results) => {
    const [projects, apps, models, fields, relationships] = results;
    dispatch({type: actions.SET_PROJECTS, projects});
    dispatch({type: actions.SET_APPS, apps});
    dispatch({type: actions.SET_MODELS, models});
    dispatch({type: actions.SET_FIELDS, fields});
    dispatch({type: actions.SET_RELATIONSHIPS, relationships});
    return { projects, apps, models, fields, relationships}
  })
}


export {
  fetchAll, anonymousLogin,
  createProject, deleteProject, updateProject,
  addApp, deleteApp,
  addModel, deleteModel, updateModel,
  addField, deleteField, updateField,
  addRelationship, deleteRelationship, updateRelationship, 
};