import React from 'react';
import { Check, X } from 'react-bootstrap-icons';
import { Card, ButtonGroup, Button } from 'react-bootstrap';


const ConfirmDelete = (props) => {
  const {onConfirm, onCancel} = props;

  const title = props.title || "Really Delete?"

  return <Card style={props.style || {}}>
    <Card.Header className="p-1">
      {title}
    </Card.Header>
    <ButtonGroup
      size="sm"
    >
      <Button
        variant="light"
        onClick={() => onCancel()}>
        Cancel <X size={20} />
      </Button>
      <Button
        variant="danger"
        onClick={onConfirm}
        onBlur={() => onCancel()}
      >
        Confirm <Check size={20} /> 
      </Button>
    </ButtonGroup>
  </Card>
};

export default ConfirmDelete;
