import React, { useState, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { withDjangoBuilderNamedStores } from '../../store/state';
import { withNamedStores } from '../../../store/state';
import { relationship_widget } from '../../rendering/widgets'
import { Trash } from 'react-bootstrap-icons';
import { deleteRelationship, updateRelationship } from '../../requests';
import Editable from '../../Editable';
import EditableChoice from '../../EditableChoice';
import actions from '../../store/actions';
import ConfirmDelete from '../../pages/ConfirmDelete';


const styleDnD = {
  border: '1px dashed gray',
  backgroundColor: '#EEEEEE',
  cursor: 'move',
};

const styleNormal = {
  border: '1px solid white',
};

function Relationship(props) {

  console.debug('Relationship', props);

  const { model, apps, models, open_api, jwt, dispatch, relationship, dragField, dropComplete, index } = props;
  const { uuid } = relationship;
  const type_choices = open_api.components.schemas.Relationship_type_Enum.enum;
  const on_delete_choices = open_api.components.schemas.Relationship_on_delete_Enum.enum;
  
  const [editableRelationshipName, setEditableRelationshipName] = useState(relationship.name);
  const [editableRelationshipType, setEditableRelationshipType] = useState(relationship.type);
  const [editableRelationshipArgs, setEditableRelationshipArgs] = useState(relationship.args);
  const [editableRelationshipTo, setEditableRelationshipTo] = useState(relationship.to);
  const [editableRelationshipOnDelete, setEdtiableRelationshipOnDelete] = useState(relationship.on_delete);

  const [hover, setHover ] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState("hidden");
  
  const icon_height = 14;
  const icon_color = '#000';
  const opacity = hover ? "100%" : "20%";

  const updateRelationshipValues = (values) => {
    updateRelationship(jwt, relationship.uuid, values).then((relationship) => {
      dispatch({type: actions.SET_RELATIONSHIP, relationship});
    })
  }

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: model,
    collect(monitor) {
        return {
            handlerId: monitor.getHandlerId(),
        };
    },
    drop() {
      dropComplete()
    },
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Get pixels to the top
      dragField(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: model,
    item: () => {
        return { uuid, index };
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
    }),
  });
  const style = isDragging ? styleDnD : styleNormal;
  const opacityDrag = isDragging ? 0 : 1;

  drag(drop(ref));

  const editableName = <Editable
    value={editableRelationshipName}
    onInput={(value) => setEditableRelationshipName(value)}
    finishEdit={() => updateRelationshipValues({name: editableRelationshipName})}
  />

  const editableType = <EditableChoice
    value={editableRelationshipType}
    choices={type_choices}
    onChange={(value) => setEditableRelationshipType(value)}
    finishEdit={() => updateRelationshipValues({type: editableRelationshipType})}
  />

  const getToSuggestions = () => {
    return Object.values(models).map(
      (model) => {
        const app = Object.values(apps).find(app => app.uuid === model.app);
        return `${app.module}.models.${model.name}`
      }
    )
  }

  const editableTo = <Editable
    value={editableRelationshipTo}
    onInput={(value) => setEditableRelationshipTo(value)}
    finishEdit={() => updateRelationshipValues({to: editableRelationshipTo})}
    suggestions={[
      "auth.User",
      ...getToSuggestions()
    ]}
  />

  const editableArgs = <Editable
    value={editableRelationshipArgs}
    onInput={(value) => setEditableRelationshipArgs(value)}
    finishEdit={() => updateRelationshipValues({args: editableRelationshipArgs})}
    suggestions={[
      "blank=True",
      "blank=True, null=True",
      "blank=False",
      `related_name="${relationship.name}s"`
    ]}
  />

  const editableOnDelete = <EditableChoice
    value={editableRelationshipOnDelete}
    choices={on_delete_choices}
    onChange={(value) => setEdtiableRelationshipOnDelete(value)}
    finishEdit={() => updateRelationshipValues({on_delete: editableRelationshipOnDelete})}
  />

  const edtiableRelationship = {
    name: editableName,
    type: editableType,
    args: editableArgs,
    to: editableTo,
    on_delete: editableOnDelete
  }

  const doConfirmedDelete = () => {
    deleteRelationship(jwt, dispatch, relationship);
  }

  return (
    <div ref={ref} style={{ ...style, opacityDrag }} data-handler-id={handlerId} onBlur={() => setDeleteVisibility("hidden")}>
      <div
        className="d-inline-block position-relative mr-5"
        onMouseOver={() => setHover(true) }
        onMouseOut={() => setHover(false) }
        role="button"
      >
        <ConfirmDelete
          title={`Delete '${relationship.name}'?`}
          style={{visibility: deleteVisibility, zIndex: 9999, position: "absolute"}}
          onConfirm={doConfirmedDelete}
          onCancel={() => setDeleteVisibility("hidden")}
        />

        <span role="button">
          <Trash
            role="button"
            onClick={() => setDeleteVisibility("visible")}
            color={icon_color}
            size={icon_height}
            className="position-absolute"
            style={{
              top: "50%",
              marginTop: (-icon_height/2) + "px",
              opacity: opacity,
            }}
          />
        </span>
        <span>
          {relationship_widget(edtiableRelationship)}
        </span>
      </div>
    </div>
  )
}

export default withNamedStores(
  withDjangoBuilderNamedStores(
    Relationship, ['dispatch', 'fields', "models", "apps"]
  ), ['jwt', 'open_api']
);