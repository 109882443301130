import React, { useState } from 'react';
import { withNamedStores } from '../../store/state';
import { withDjangoBuilderNamedStores } from '../store/state'
import { updateProject } from '../requests'
import actions from '../store/actions';

import { Table } from 'react-bootstrap';

import './Project.css';


function toggleSwitch(value, toggle) {
  return <label className="switch ml-2">
    <input defaultChecked={value} type="checkbox" onClick={() => toggle()} />
    <span className="slider"></span>
  </label>;
}


function ProjectConfig(props) {

  console.debug("ProjectPage:", props)

  const { projectData, jwt, dispatch} = props;

  const { use_poetry, use_channels, use_rest_framework } = projectData;

  const [poetry, setUsePoetry] = useState(use_poetry);
  const [channels, setUseChannels] = useState(use_channels);
  const [rest_framework, setUseRestFramework] = useState(use_rest_framework);

  const setProjectField = (k, v) => {
    updateProject(jwt, projectData.uuid, {[`${k}`]: v}).then((project) => {
      dispatch({type: actions.SET_PROJECT, project});
    })
  }

  const togglePoetry = () => {
    setUsePoetry(!poetry);
    setProjectField("use_poetry", !poetry)
  }

  const toggleChannels = () => {
    setUseChannels(!channels)
    setProjectField("use_channels", !channels)
  }

  const toggleRestFramework = () => {
    setUseRestFramework(!rest_framework)
    setProjectField("use_rest_framework", !rest_framework)
  }
  
  return <>
    <h5>Project Config</h5>
    <Table size="sm" borderless>
    <thead>
    <tr>
        <td style={{width: "40%"}}>Poetry</td>
        <td>
        {toggleSwitch(poetry, togglePoetry)}
        </td>
    </tr>
    <tr>
        <td>Django Channels</td>
        <td>
        {toggleSwitch(channels, toggleChannels)}
        </td>
    </tr>
    <tr>
        <td>Django Rest Framework</td>
        <td>
        {toggleSwitch(rest_framework, toggleRestFramework)}
        </td>
    </tr>
    </thead>
    </Table>
</>
}

export default withNamedStores(
    withDjangoBuilderNamedStores(
        ProjectConfig, ['projects', 'dispatch']
  ),['user', 'jwt']
);

